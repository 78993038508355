import { EnvironmentService } from '@lancelot-frontend/environment';

export function appWebInitializerFactory(
  environmentService: EnvironmentService,
) {
  return () =>
    new Promise<void>((resolve) => {
      // Add script for FACIL'iti
      const facilitiScript = document.createElement('script');
      facilitiScript.setAttribute(
        'src',
        'https://cdn.facil-iti.app/tags/faciliti-tag.min.js',
      );
      facilitiScript.dataset.applicationIdentifier =
        environmentService.get('facility')?.appId;

      document.head.appendChild(facilitiScript);

      // Add script to load Google Tag Manager
      const googleTagManagerId = environmentService.get('googleTagManagerId');
      if (googleTagManagerId) {
        const loadGoogleTagManagerScript = document.createElement('script');
        loadGoogleTagManagerScript.text = `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${googleTagManagerId}');
      `;
        document.head.appendChild(loadGoogleTagManagerScript);

        const loadGoogleTagManagerNoScript = document.createElement('noscript');
        loadGoogleTagManagerNoScript.innerHTML = `
          <iframe src="https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
        document.body.appendChild(loadGoogleTagManagerNoScript);
      }

      // Add script to load Facebook JS SDK
      const loadFacebookScript = document.createElement('script');
      loadFacebookScript.text = `
      (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/fr_FR/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    `;
      document.body.appendChild(loadFacebookScript);

      window.fbAsyncInit = function () {
        FB.init({
          appId: environmentService?.get('facebook')?.appId,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v13.0',
        });
      };

      resolve();
    });
}
