<tui-root>
  <!--  Environment banner  -->
  @if (
    !app.isNativePlatform &&
    !app.embedded &&
    environment &&
    environment !== 'development' &&
    environment !== 'production'
  ) {
    <ng-container ngProjectAs="tuiOverContent">
      <div
        class="banner-container fixed bottom-0 left-0 right-0 mxn3 bg-white no-print"
      >
        <tui-badge
          class="banner full-width justify-center"
          [appearance]="
            environment === 'preprod'
              ? 'warning'
              : environment === 'recette'
                ? 'info'
                : 'success'
          "
          size="xl"
          >{{ environment }}
        </tui-badge>
      </div>
    </ng-container>
  }

  <!--  Login as banner  -->
  @if (!app.loading && app.loginAs) {
    <ng-container ngProjectAs="tuiOverContent">
      <div
        class="banner-container fixed bottom-0 left-0 right-0 mxn3 bg-error-fill p1 no-print"
        [ngStyle]="
          environment &&
          environment !== 'development' &&
          environment !== 'production'
            ? { bottom: '2.5rem' }
            : {}
        "
        *tuiLet="signedInUserService.signedInPerson$ | async as signedInPerson"
      >
        <div
          *transloco="let t"
          class="banner flex flex-row gap3 items-center justify-center"
        >
          <p class="tui-text_body-m-2 color-text-primary-on-accent-1">
            {{ t('notifications.loggedInAs') }}
            {{
              stringifyPersonInformation(signedInPerson, {
                ffbId: true,
                lastName: true,
                firstName: true
              })
            }}
          </p>
          <button
            tuiButton
            size="xs"
            iconStart="@tui.x"
            appearance="primary-on-accent"
            (click)="removeLoginAs()"
          >
            {{ t('actions.cancel') }}
          </button>
        </div>
      </div>
    </ng-container>
  }

  <!--  App content  -->
  @if ((outdated$ | async) === false && connected) {
    @if (app.isNativePlatform) {
      <tui-pull-to-refresh (pulled)="refresh()">
        <ffb-app-layout [ngClass]="{ loading }"></ffb-app-layout>
      </tui-pull-to-refresh>
    } @else {
      <ffb-app-layout [ngClass]="{ loading }"></ffb-app-layout>
    }
  }

  <!--  Loading / waiting / blocking overlay  -->
  @if (loading || waiting || (refreshing && isNativePlatform)) {
    <ffb-loading-overlay
      [backgroundColor]="
        loading ? 'accent' : refreshing ? 'transparent' : undefined
      "
      [logo]="loading"
    ></ffb-loading-overlay>
  }

  <ng-container *transloco="let t">
    <!--  Offline app overlay -->
    @if (!connected) {
      <ffb-overlay backgroundColor="accent">
        <tui-icon
          icon="@tui.wifi-off"
          [style]="{ height: '4rem', width: '4rem' }"
        ></tui-icon>
        <div class="py4 max-width-2">
          <p>{{ t('errors.offline') }}</p>
        </div>
      </ffb-overlay>
    }

    <!--  Outdated app overlay  -->
    @if ((outdated$ | async) === true) {
      <ffb-overlay backgroundColor="accent" class="outdated-screen">
        <ffb-logo appearance="on-accent"></ffb-logo>
        <div class="py4 max-width-2">
          <h3 class="tui-text_h3 mb2">
            {{ t('notifications.appUpdateMandatory') }}
          </h3>
          <p>{{ t('notifications.appUpdateMandatory.moreInfo') }}</p>
        </div>
        <ng-container
          *ngTemplateOutlet="
            updateAppButton;
            context: { appearance: 'primary-on-accent' }
          "
        ></ng-container>
      </ffb-overlay>
    }

    <!--  App under maintenance overlay -->
    @if (underMaintenance) {
      <ffb-overlay backgroundColor="accent" class="maintenance-screen">
        <ffb-logo appearance="on-accent"></ffb-logo>
        <div class="py4 max-width-2">
          <h3 class="tui-text_h3 mb2">{{ t('maintenance.title') }}</h3>
          <p>{{ t('maintenance.content') }}</p>
        </div>
        <a tuiButton appearance="primary-on-accent" (click)="reload()">
          {{ t('actions.reload') }}
        </a>
      </ffb-overlay>
    }

    <!--  App update available alert  -->
    <ng-template #appUpdateAvailableTemplate>
      <p class="mb1">{{ t('notifications.appUpdateAvailable') }}</p>
      <ng-container *ngTemplateOutlet="updateAppButton"></ng-container>
    </ng-template>

    <!--  App update downloading alert  -->
    <ng-template #appUpdateDownloadingTemplate>
      <p>{{ t('notifications.appUpdateDownloading') }}</p>
    </ng-template>

    <!--  App update error alert  -->
    <ng-template #appUpdateErrorTemplate>
      <p>{{ t('notifications.appUpdateError') }}</p>
    </ng-template>

    <!--  App update ready to install alert  -->
    <ng-template #appUpdateReadyTemplate>
      <p class="mb1">{{ t('notifications.appUpdateReady') }}</p>
      <button
        tuiButton
        type="button"
        appearance="whiteblock"
        size="s"
        class="full-width"
        (click)="completeFlexibleUpdate()"
      >
        {{ t('actions.reload') }}
      </button>
    </ng-template>

    <!--  Update app button  -->
    <ng-template #updateAppButton let-appearance="appearance">
      <button
        tuiButton
        type="button"
        [appearance]="appearance || 'whiteblock'"
        size="m"
        class="max-content-width"
        (click)="updateApp()"
      >
        {{ t('actions.update') }}
      </button>
    </ng-template>
  </ng-container>
</tui-root>
