import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { AuthenticationService } from '../app/authentication/authentication.service';
import { SignedInUserService } from '../app/user/user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private environmentService = inject(EnvironmentService);
  private auth = inject(AuthenticationService);
  private signedInUserService = inject(SignedInUserService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    // If we are requesting the api and a firebaseIdToken is set,
    // clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    if (
      request.url.startsWith(this.environmentService.get('apiBaseUrl', '/api'))
    ) {
      return from(this.auth.getIdToken()).pipe(
        switchMap((firebaseIdToken) => {
          if (firebaseIdToken) {
            const authentifiedRequest = request.clone({
              headers: request.headers.set(
                'Authorization',
                `Bearer ${firebaseIdToken}`,
              ),
            });

            return next.handle(authentifiedRequest);
          }

          return next.handle(request);
        }),
      );
    }

    // If we are requesting the cms and a user with a cmsToken is set,
    // clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    if (
      request.url.startsWith(this.environmentService.get('cmsBaseUrl', '/cms'))
    ) {
      return this.signedInUserService.signedInUser$.pipe(
        distinctUntilChanged(
          (a, b) =>
            a?.cmsToken === b?.cmsToken &&
            a?.person?.emailVerified === b?.person?.emailVerified,
        ),
        switchMap((user) => {
          if (user?.cmsToken && user?.person?.emailVerified) {
            const authentifiedRequest = request.clone({
              headers: request.headers.set(
                'Authorization',
                `Bearer ${user.cmsToken}`,
              ),
            });

            return next.handle(authentifiedRequest);
          }

          return next.handle(request);
        }),
      );
    }

    return next.handle(request);
  }
}
