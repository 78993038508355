import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ActivatedRouteSnapshot,
  PRIMARY_OUTLET,
  RouterStateSnapshot,
  TitleStrategy,
} from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DocumentHeadService } from '@lancelot-frontend/core';

const DEFAULT_TITLE_TRANSLATION_KEY = 'title';
const DEFAULT_DESCRIPTION_TRANSLATION_KEY = 'description';

@Injectable({ providedIn: 'root' })
export class AppTitleStrategy extends TitleStrategy {
  private translocoService = inject(TranslocoService);
  private documentHeadService = inject(DocumentHeadService);

  private readonly _loadedTranslationScopes$ = new BehaviorSubject<
    Record<string, boolean>
  >({});

  constructor() {
    super();

    // Keep tracks of loaded translation scopes
    this.translocoService.events$
      .pipe(
        filter((e) => e.type === 'translationLoadSuccess'),
        takeUntilDestroyed(),
      )
      .subscribe((e) => {
        this._loadedTranslationScopes$.next({
          ...this._loadedTranslationScopes$.getValue(),
          [e.payload.scope || 'main']: true,
        });
      });
  }

  /**
   * @returns The title and description translation keys of the deepest primary route.
   */
  buildTranslationKeys(snapshot: RouterStateSnapshot): {
    descriptionTranslationKey: string;
    shouldUpdateTitle: boolean;
    titleTranslationKey: string;
  } {
    let shouldUpdateTitle = true;
    let titleTranslationKey = DEFAULT_TITLE_TRANSLATION_KEY;
    let descriptionTranslationKey = DEFAULT_DESCRIPTION_TRANSLATION_KEY;
    let route: ActivatedRouteSnapshot | undefined = snapshot.root;

    while (route !== undefined) {
      shouldUpdateTitle =
        this.getResolvedUpdateTitleForRoute(route) ?? shouldUpdateTitle;
      titleTranslationKey =
        this.getResolvedTitleTranslationKeyForRoute(route) ??
        titleTranslationKey;
      descriptionTranslationKey =
        this.getResolvedDescriptionTranslationKeyForRoute(route) ??
        descriptionTranslationKey;
      route = route.children.find((child) => child.outlet === PRIMARY_OUTLET);
    }

    return {
      shouldUpdateTitle,
      titleTranslationKey,
      descriptionTranslationKey,
    };
  }

  /**
   * Given an `ActivatedRouteSnapshot`, returns the final value of the
   * `Route.data.updateTitle` property, which should be a boolean.
   */
  getResolvedUpdateTitleForRoute(snapshot: ActivatedRouteSnapshot) {
    return snapshot.data.updateTitle;
  }

  /**
   * Given an `ActivatedRouteSnapshot`, returns the final value of the
   * `Route.data.titleTranslationKey` property, which should be a static string.
   */
  getResolvedTitleTranslationKeyForRoute(snapshot: ActivatedRouteSnapshot) {
    return snapshot.data.titleTranslationKey;
  }

  /**
   * Given an `ActivatedRouteSnapshot`, returns the final value of the
   * `Route.data.descriptionTranslationKey` property, which should be a static string.
   */
  getResolvedDescriptionTranslationKeyForRoute(
    snapshot: ActivatedRouteSnapshot,
  ) {
    return snapshot.data.descriptionTranslationKey;
  }

  async translate(translationKey: string) {
    const translationKeyScope = translationKey.split('.')[1]
      ? translationKey.split('.')[0]
      : 'main';

    if (!this._loadedTranslationScopes$.getValue()[translationKeyScope]) {
      await firstValueFrom(
        this._loadedTranslationScopes$.pipe(
          filter(
            (loadedTranslationScopes) =>
              loadedTranslationScopes[translationKeyScope],
          ),
        ),
      );
    }

    return this.translocoService.translate(translationKey);
  }

  async getTitle(snapshot: RouterStateSnapshot) {
    const { shouldUpdateTitle, titleTranslationKey } =
      this.buildTranslationKeys(snapshot);
    if (!shouldUpdateTitle) {
      return Promise.resolve(null);
    }
    return await this.translate(titleTranslationKey);
  }

  override updateTitle(snapshot: RouterStateSnapshot) {
    const {
      shouldUpdateTitle,
      titleTranslationKey,
      descriptionTranslationKey,
    } = this.buildTranslationKeys(snapshot);

    if (shouldUpdateTitle) {
      this.documentHeadService.setTitle(this.translate(titleTranslationKey));
      this.documentHeadService.setDescription(
        this.translate(descriptionTranslationKey),
      );
    }
  }
}
