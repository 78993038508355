import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { identity } from 'rxjs';
import { skip, switchMap, take } from 'rxjs/operators';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { AppService } from '../app/app.service';
import { AuthenticationService } from '../app/authentication/authentication.service';

@Injectable()
export class SwitchUserInterceptor implements HttpInterceptor {
  private environmentService = inject(EnvironmentService);
  private app = inject(AppService);
  private auth = inject(AuthenticationService);

  constructor() {
    // reset loginAs on sign-in/sign-out
    this.auth.firebaseUser$.pipe(skip(1)).subscribe(() => {
      this.app.loginAs = null;
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    // If we are requesting the api and auth.loginAs is set,
    // clone the request and replace the original headers with
    // cloned headers, updated with the X-Switch-User header.
    if (
      request.url.startsWith(this.environmentService.get('apiBaseUrl', '/api'))
    ) {
      return this.app.loginAs$.pipe(
        request.method !== 'GET' ? take(1) : identity,
        switchMap((firebaseUid) => {
          if (firebaseUid) {
            const authentifiedRequest = request.clone({
              headers: request.headers.set('X-Switch-User', firebaseUid),
            });

            return next.handle(authentifiedRequest);
          }

          return next.handle(request);
        }),
      );
    }

    return next.handle(request);
  }
}
