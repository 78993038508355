import { PlatformLocation } from '@angular/common';
import {
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject, makeStateKey } from '@angular/core';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { IS_REFRESHABLE, onCancelled } from '@lancelot-frontend/core';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { AppService } from '../app/app.service';

@Injectable()
export class RefreshInterceptor implements HttpInterceptor {
  private location = inject(PlatformLocation);
  private environmentService = inject(EnvironmentService);
  private app = inject(AppService);

  private toRefresh: Map<string, boolean> = new Map();
  private refreshing: Map<string, boolean> = new Map();

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    const key = makeStateKey<string>(
      this.location.href + request.urlWithParams,
    );
    this.toRefresh.set(key, true);

    // If we are requesting the api or the cms,
    // redo the request each time the refresh timestamp is updated
    if (
      request.context.get(IS_REFRESHABLE) &&
      request.method === 'GET' &&
      (request.url.startsWith(
        this.environmentService.get('apiBaseUrl', '/api'),
      ) ||
        request.url.startsWith(
          this.environmentService.get('cmsBaseUrl', '/cms'),
        ))
    ) {
      return this.app.refreshingTimestamp$.pipe(
        distinctUntilChanged(),
        filter(
          () =>
            !!this.toRefresh.get(this.location.href + request.urlWithParams),
        ),
        switchMap((timestamp) => {
          if (!timestamp) {
            return next.handle(request);
          }

          const alreadyRefreshing = this.refreshing.get(key);

          if (!alreadyRefreshing) {
            this.refreshing.set(key, true);
            this.app.refreshingContent = true;
          }

          return next
            .handle(request.clone({ setParams: { r: timestamp.toString() } }))
            .pipe(
              onCancelled(() => {
                this.refreshing.delete(key);
                this.app.refreshingContent = false;
              }),
              tap({
                next: (event) => {
                  if (event.type === HttpEventType.Response) {
                    this.refreshing.delete(key);
                    this.app.refreshingContent = false;
                  }
                },
                error: () => {
                  this.refreshing.delete(key);
                  this.app.refreshingContent = false;
                },
              }),
            );
        }),
      );
    }

    return next.handle(request);
  }
}
