import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { AppService } from '../app/app.service';
import { AuthenticationService } from '../app/authentication/authentication.service';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  private environmentService = inject(EnvironmentService);
  private app = inject(AppService);
  private auth = inject(AuthenticationService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((error) => {
        // Set app status regarding the error
        this.setAppErrorStatus(request, error);
        // Rethrow the error for the component to handle it
        throw error;
      }),
    );
  }

  private setAppErrorStatus(
    request: HttpRequest<unknown>,
    error: HttpErrorResponse,
  ) {
    if (error.status === 0 || error.status >= 500) {
      if (
        request.url.startsWith(
          this.environmentService.get('apiBaseUrl', '/api'),
        )
      ) {
        if (error.status === 503) {
          this.app.apiUnderMaintenance = true;
        }
      }
      if (
        request.url.startsWith(
          this.environmentService.get('cmsBaseUrl', '/cms'),
        )
      ) {
        if (error.status === 503) {
          this.app.cmsUnderMaintenance = true;
        } else {
          this.app.cmsDown = true;
        }
      }
    }
    if (error.status === 401) {
      if (
        request.url.startsWith(
          this.environmentService.get('apiBaseUrl', '/api'),
        )
      ) {
        this.auth.signOut();
      }
    }
  }
}
