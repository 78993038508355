import { Routes } from '@angular/router';
import { ResourceNotFoundComponent } from '@lancelot-frontend/components';
import { shouldNotBeRedirected } from './app-guards';
import { Layouts } from './layout/app.layout';

export const routes: Routes = [
  {
    path: 'news',
    loadChildren: () => import('./news/routes'),
    data: { preload: true, delay: 5000 },
  },
  {
    path: 'partners',
    loadChildren: () => import('./partners/routes'),
  },
  {
    path: 'about-bridge/the-federation',
    loadChildren: () => import('./federation/routes'),
  },
  {
    path: 'about-bridge',
    loadChildren: () => import('./articles/routes'),
  },
  {
    path: 'as-de-trefle',
    loadChildren: () => import('./as-de-trefle/routes'),
  },
  {
    path: 'le-monde-bridge-problems',
    loadChildren: () => import('./le-monde-bridge-problems/routes'),
  },
  {
    path: 'p',
    loadChildren: () => import('./cms-pages/free-page/routes'),
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/routes'),
  },
  {
    path: 'newsletter',
    loadChildren: () => import('./newsletter/routes'),
  },
  {
    path: 'press',
    loadChildren: () => import('./press/routes'),
  },
  {
    path: 'legal-notice',
    loadChildren: () => import('./legal-notice/routes'),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/routes'),
  },
  {
    path: 'mobile-app-download',
    loadChildren: () => import('./mobile-app-download/routes'),
    data: {
      layout: Layouts.Empty,
    },
  },
  {
    path: 'help',
    loadChildren: () => import('./help/routes'),
  },
  {
    path: 'practical-information',
    loadChildren: () => import('./practical-information/routes'),
  },
  { path: 'login', redirectTo: 'auth/login' },
  {
    path: 'auth',
    loadChildren: () => import('./authentication/routes'),
    data: {
      layout: Layouts.Auth,
      preload: true,
      delay: 5000,
    },
  },
  {
    path: 'competitions',
    loadChildren: () => import('./competitions/routes'),
    data: {
      layout: Layouts.Dashboard,
    },
  },
  {
    path: 'embeddable-forms',
    loadChildren: () => import('./embeddable-forms/routes'),
  },
  {
    path: '**',
    component: ResourceNotFoundComponent,
    canActivate: [shouldNotBeRedirected],
    data: { resource: 'page' },
  },
];
