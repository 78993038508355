import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { EnvironmentService } from '@lancelot-frontend/environment';

@Injectable()
export class CmsTargetAppInterceptor implements HttpInterceptor {
  private environmentService = inject(EnvironmentService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    if (
      request.url.startsWith(
        this.environmentService.get('cmsBaseUrl', '/cms'),
      ) &&
      !request.params.get('targetApp')
    ) {
      return next.handle(
        request.clone({
          params: request.params.set(
            'targetApp',
            Capacitor.isNativePlatform() ? Capacitor.getPlatform() : 'web',
          ),
        }),
      );
    }

    return next.handle(request);
  }
}
