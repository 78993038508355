import { isPlatformServer } from '@angular/common';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { LangService } from '@lancelot-frontend/core';
import { EnvironmentService } from '@lancelot-frontend/environment';

@Injectable()
export class CmsLocaleInterceptor implements HttpInterceptor {
  private platformId = inject(PLATFORM_ID);
  private environmentService = inject(EnvironmentService);
  private langService = inject(LangService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    if (
      request.url.startsWith(
        this.environmentService.get('cmsBaseUrl', '/cms'),
      ) &&
      !request.params.get('locale')
    ) {
      if (isPlatformServer(this.platformId)) {
        const localizedRequest = request.clone({
          params: request.params.set(
            'locale',
            this.langService.getActiveLang(),
          ),
        });

        return next.handle(localizedRequest);
      }

      return this.langService.langChanges$.pipe(
        switchMap((lang) => {
          const localizedRequest = request.clone({
            params: request.params.set('locale', lang),
          });

          return next.handle(localizedRequest);
        }),
      );
    }

    return next.handle(request);
  }
}
