import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { EnvironmentService } from '@lancelot-frontend/environment';

const urlsToRedirectToEASI = [
  '/all-clubs.json',
  '/clubs',
  '/view/article',
  '/festivals',
  '/competitions-nationales',
  '/map/trouver-club-ffb-bridge-box',
  '/download',
  '/cours-en-ligne',
  '/banque',
  '/bridgebox',
  '/duodechoc',
  '/videoduodechoc',
  '/initiationenligne',
  '/tiktok',
  '/gestion/compte',
  '/premiere-connexion',
  '/validation-informations-utilisateur',
  '/post-conditions-generales-d-utilisation',
  '/conditions-generales-d-utilisation',
  '/find-email-phone-by-licence-number',
  '/check-email',
  '/email-validation',
  '/creation-compte',
  '/confirmation',
  '/confirmation-de-votre-inscription',
];

const urlsToRedirectInternally = [
  { url: '/petitbridge', redirectTo: '/p/petit-bridge' },
  { url: '/lepetitbridge', redirectTo: '/p/petit-bridge' },
  { url: '/petit-bridge-in-english', redirectTo: '/p/petit-bridge-in-english' },
  {
    url: '/tout-savoir-sur-la-ffb-bridge-box',
    redirectTo: '/p/tout-savoir-sur-la-ffb-bridge-box',
  },
  {
    url: '/je-veux-decouvrir-l-espace-licencie',
    redirectTo: '/p/prendre-sa-licence',
  },
  { url: '/base-documentaire', redirectTo: '/p/education-nationale' },
  {
    url: '/vivre-le-bridge-et-le-faire-vivre',
    redirectTo: '/p/universite-du-bridge',
  },
  { url: '/j-apprends', redirectTo: '/p/universite-du-bridge-j-apprends' },
  {
    url: '/mon-profil-de-bridgeur',
    redirectTo: '/p/universite-du-bridge-mon-profil-de-bridgeur',
  },
  {
    url: '/je-joue',
    redirectTo: '/p/universite-du-bridge-je-commence-a-jouer',
  },
  { url: '/j-enseigne', redirectTo: '/p/universite-du-bridge-j-enseigne' },
  {
    url: '/je-suis-eleve-ou-etudiant',
    redirectTo: '/p/universite-du-bridge-je-suis-eleve-ou-etudiant',
  },
  {
    url: '/les-equipes-de-france-jeunes',
    redirectTo: '/p/universite-du-bridge-les-equipes-de-france-jeunes',
  },
  { url: '/j-organise', redirectTo: '/p/universite-du-bridge-j-organise' },
  { url: '/la-recherche', redirectTo: '/p/universite-du-bridge-la-recherche' },
  {
    url: '/qui-sommes-nous',
    redirectTo: '/p/universite-du-bridge-qui-sommes-nous',
  },
  { url: '/reconquerir', redirectTo: '/p/reconquerir' },
  { url: '/recruter', redirectTo: '/p/recruter' },
  { url: '/rebondir', redirectTo: '/p/rebondir' },
  { url: '/avantages-licencies', redirectTo: '/p/avantages-licencies' },
  { url: '/apprendre-le-bridge', redirectTo: '/p/apprendre-le-bridge' },
  {
    url: '/decouvrir-le-bridge',
    redirectTo: '/about-bridge/decouvrir-le-bridge',
  },
  { url: '/bridge-educatif', redirectTo: '/about-bridge/bridge-educatif' },
  { url: '/sport-de-l-esprit', redirectTo: '/about-bridge/sport-de-l-esprit' },
  { url: '/partenaires-ffb', redirectTo: '/partners' },
  {
    url: '/federation-francaise-de-bridge',
    redirectTo: '/about-bridge/the-federation',
  },
  { url: '/informations-pratiques', redirectTo: '/practical-information' },
  { url: '/mentions-legales', redirectTo: '/legal-notice' },
  { url: '/espace-presse', redirectTo: '/press' },
  { url: '/faq', redirectTo: '/help' },
  { url: '/zenguide', redirectTo: '/help' },
  { url: '/aide-connexion', redirectTo: '/help#connexion' },
  { url: '/hackathon', redirectTo: '/p/hackathon-bridge-2022' },
  { url: '/bridge-en-ligne', redirectTo: '/p/bridge-en-ligne' },
];

export const shouldNotBeRedirected: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  { url }: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const environmentService = inject(EnvironmentService);
  const platformId = inject(PLATFORM_ID);

  // Home page should be user dashboard on native platforms
  if (url === '/') {
    if (Capacitor.isNativePlatform()) {
      return router.parseUrl('/user/dashboard');
    }

    return true;
  }

  for (const urlToRedirect of urlsToRedirectToEASI) {
    if (url.startsWith(urlToRedirect) && isPlatformBrowser(platformId)) {
      window.location.replace(
        environmentService.get('easiPublicUrl') +
          url +
          (url.includes('?') ? '&' : '?') +
          'lancelot',
      );
    }
  }

  for (const { url: urlToRedirect, redirectTo } of urlsToRedirectInternally) {
    if (url.startsWith(urlToRedirect)) {
      return router.parseUrl(redirectTo);
    }
  }

  return true;
};
