import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationConfig, mergeApplicationConfig } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserOptions } from '@sentry/angular';
import { NgDompurifySanitizer } from '@taiga-ui/dompurify';
import { TUI_SANITIZER } from '@taiga-ui/legacy';
import {
  ROUTER_SCROLL_SERVICE,
  RouterScrollService,
} from '@lancelot-frontend/core';
import { AuthInterceptor } from '../http-interceptors/auth.interceptor';
import { BrowserStateInterceptor } from '../http-interceptors/browser-state.interceptor';
import { CacheInterceptor } from '../http-interceptors/cache.interceptor';
import { RefreshInterceptor } from '../http-interceptors/refresh.interceptor';
import { SwitchUserInterceptor } from '../http-interceptors/switch-user.interceptor';
import { appConfig } from './app.config';
import { AppRouteReuseStrategy } from './app-route-reuse-strategy';

export const sentryConfig: Pick<BrowserOptions, 'denyUrls' | 'ignoreErrors'> = {
  ignoreErrors: [
    // See https://github.com/getsentry/sentry-javascript/issues/2292
    'Non-Error exception captured',
    // Random plugins/extensions
    'top.GLOBALS',
    'canvas.contentDocument',
    'change_ua',
    'safari-web-extension',
    'webkit-masked-url',
    'src/sdk/SDK',
    'src/services/LanguagePack',
    'scs/abc-static/',
    // Facebook borked
    'fb_xd_fragment',
    // Loading chunk and translations failed
    'Loading chunk',
    'dynamically imported module',
    'Importing a module script failed',
    'Unable to load translation',
    // Cobrowse errors
    'CobrowseIO',
    'Unhandled error. (undefined)',
    // Firebase errors
    'Firebase:',
    '@firebase/',
    'auth/network-request-failed',
    'installations/app-offline',
    // Sentry error
    '@sentry/',
    // Misc
    'NetworkError',
    "Failed to read the 'localStorage'",
    'ResizeObserver loop completed with undelivered notifications.',
    "type 'module' in RegistrationOptions is not implemented yet",
    'zone.js/fesm2015',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
};

const clientConfig: ApplicationConfig = {
  providers: [
    {
      provide: TUI_SANITIZER,
      useClass: NgDompurifySanitizer,
    },
    {
      provide: ROUTER_SCROLL_SERVICE,
      useClass: RouterScrollService,
    },
    {
      provide: RouteReuseStrategy,
      useExisting: AppRouteReuseStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SwitchUserInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
  ],
};

export const appClientConfig = mergeApplicationConfig(appConfig, clientConfig);
