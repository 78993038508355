import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AppService } from '../app.service';
import { UserRightsService } from '../user/user-rights.service';

export const canAccessBoardLibrary: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  { url }: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const app = inject(AppService);
  const userRightsService = inject(UserRightsService);

  return app.addRouterGuard(
    'canAccessBoardLibrary',
    url,
    userRightsService.canAccessBoardLibrary$.pipe(
      filter((canAccessBoardLibrary) => canAccessBoardLibrary !== undefined),
      map((canAccessBoardLibrary) => {
        if (!canAccessBoardLibrary) {
          return router.createUrlTree(['auth', 'missing-permission']);
        }

        return true;
      }),
    ),
    { comeBackToRefusedUrlOnValueChange: true },
  );
};
