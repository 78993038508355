import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<unknown>): Observable<unknown> {
    if (route.data?.['preload'] && route.path !== null) {
      const delay: number = route.data['delay'] || 0;

      return timer(delay).pipe(
        flatMap(() => {
          return load();
        }),
      );
    } else {
      return of(null);
    }
  }
}
