import { Routes } from '@angular/router';
import { canAccessAdmin } from './admin/admin-guards';
import { routes as commonRoutes } from './app-routes';
import { canAccessBoardLibrary } from './board-library/board-library-guards';
import { Layouts } from './layout/app.layout';
import { canAccessTools } from './tools/tools-guards';
import {
  doesNotNeedMultifactorAuth,
  hasPerson,
  isValid,
} from './user/user-guards';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/routes'),
  },
  {
    path: 'user',
    loadChildren: () => import('./user/routes.web'),
    canActivate: [doesNotNeedMultifactorAuth, hasPerson],
    runGuardsAndResolvers: 'always',
    data: {
      layout: Layouts.Dashboard,
      preload: true,
      delay: 5000,
    },
  },
  {
    path: 'logs',
    loadChildren: () => import('./logs/routes'),
    data: {
      layout: Layouts.Empty,
    },
  },
  {
    path: 'tools',
    loadChildren: () => import('./tools/routes'),
    canActivate: [
      doesNotNeedMultifactorAuth,
      hasPerson,
      isValid,
      canAccessTools,
    ],
    runGuardsAndResolvers: 'always',
    data: {
      layout: Layouts.Empty,
    },
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/routes'),
    canActivate: [
      doesNotNeedMultifactorAuth,
      hasPerson,
      isValid,
      canAccessAdmin,
    ],
    runGuardsAndResolvers: 'always',
    data: {
      layout: Layouts.Admin,
    },
  },
  {
    path: 'education',
    loadChildren: () => import('./education/routes'),
    data: {
      layout: Layouts.Empty,
    },
  },
  {
    path: 'board-library',
    loadChildren: () => import('./board-library/routes'),
    canActivate: [
      doesNotNeedMultifactorAuth,
      hasPerson,
      isValid,
      canAccessBoardLibrary,
    ],
    runGuardsAndResolvers: 'always',
    data: {
      layout: Layouts.Empty,
    },
  },
  ...commonRoutes,
];

export { routes };
