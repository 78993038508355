import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, TransferState, inject, makeStateKey } from '@angular/core';

import { of } from 'rxjs';
import { EnvironmentService } from '@lancelot-frontend/environment';

@Injectable({
  providedIn: 'root',
})
export class BrowserStateInterceptor implements HttpInterceptor {
  private transferState = inject(TransferState);
  private environmentService = inject(EnvironmentService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    if (req.method === 'GET') {
      // TODO remove this condition as soon as we handle authentication on server side.
      const cmsBaseUrl = this.environmentService.get('cmsBaseUrl', '/cms');
      if (
        (req.url.startsWith(cmsBaseUrl + '/api/news') ||
          req.url.startsWith(cmsBaseUrl + '/api/home-page') ||
          req.url.startsWith(cmsBaseUrl + '/api/pages')) &&
        req.headers.get('authorization')
      ) {
        return next.handle(req);
      }

      const key = makeStateKey<string>(req.urlWithParams);
      const storedResponse: null | string = this.transferState.get(key, null);
      if (storedResponse) {
        const response = new HttpResponse({
          body: storedResponse,
          status: 200,
        });
        return of(response);
      }
    }

    return next.handle(req);
  }
}
